import React, { useState } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { MobileMenu } from "../mobile-menu"
import { ReactComponent as HVLogo } from "src/images/svg/HVLogo.svg"
import { SiBandcamp } from "react-icons/si"

import styles from "./header.module.css"

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }
  return (
    <>
      <div className={styles.logo}>
        <Link className={styles.logoLink} to="/">
          <HVLogo className={styles.logoSvg} />
        </Link>
      </div>

      <header className={styles.header}>
        <button
          className={cx("reset", styles.mobileMenuButton)}
          onClick={() => toggleMobileMenu()}
        >
          <span className="label">Menu</span>
        </button>
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link
                activeClassName="active"
                className={cx("label", styles.navLink)}
                to="/discography"
              >
                <span className="underline-on-hover">Discography</span>
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link
                activeClassName="active"
                className={cx("label", styles.navLink)}
                to="/videos"
              >
                <span className="underline-on-hover">Videos</span>
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link
                activeClassName="active"
                className={cx("label", styles.navLink)}
                to="/about"
              >
                <span className="underline-on-hover">About</span>
              </Link>
            </li>
            <li className={styles.navItem}>
              <a
                className={cx("label", styles.navLink)}
                href="https://homevideo.bandcamp.com"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <SiBandcamp /> <span className="underline-on-hover">Shop</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <MobileMenu open={mobileMenuOpen} toggle={toggleMobileMenu} />
    </>
  )
}
