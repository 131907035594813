import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import cx from "classnames"
import { Header } from "src/components/header"
import SEO from "src/components/seo"

import "src/styles/main.css"

const Layout = ({ children, location }) => {
  const [isClient, setClient] = useState(false)
  const [enableFocusOutlines, setEnableFocusOutlines] = useState(false)

  useEffect(() => {
    setClient(true)
    window && window.addEventListener("keydown", handleKeydown)
  }, [])

  const key = isClient ? `client` : `server`

  const handleKeydown = e => {
    // Detect a keyboard user from a tab key press
    const isTabEvent = e.keyCode === 9

    if (isTabEvent) {
      setEnableFocusOutlines(true)
    }
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: cx(enableFocusOutlines ? "" : "no-outline-on-focus"),
        }}
      />
      <div>
        <Header />
        <div className="page-container" key={key}>
          <div className="page-content">{children}</div>
        </div>
      </div>
    </>
  )
}

export default Layout
