import React, { useState } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { Modal, Fade, Backdrop } from "@material-ui/core"
import { SiBandcamp } from "react-icons/si"

import * as styles from "./mobile-menu.module.css"

export const MobileMenu = ({ open, toggle }) => {
  return (
    <Modal
      anchor="left"
      open={open}
      onClose={() => toggle()}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
    >
      <Fade
        direction="left"
        in={open}
        timeout={{
          enter: 400,
          exit: 300,
        }}
      >
        <div className={styles.drawer} role="presentation">
          <button className={cx("reset", styles.closeButton)} onClick={toggle}>
            <span className="label underline-on-hover">Close X</span>
          </button>
          <div className={cx(styles.drawerInner)}>
            <nav>
              <ul className={styles.menuList} role="menu">
                <li
                  className={cx(styles.menuItem, open ? styles.reveal : "")}
                  key="discography"
                >
                  <Link
                    activeClassName="active"
                    className={cx("label", styles.menuLink)}
                    to="/discography"
                    onClick={open ? () => toggle() : undefined}
                  >
                    <span className="underline-on-hover">Discography</span>
                  </Link>
                </li>
                <li
                  className={cx(styles.menuItem, open ? styles.reveal : "")}
                  key="videos"
                >
                  <Link
                    activeClassName="active"
                    className={cx("label", styles.menuLink)}
                    to="/videos"
                    onClick={open ? () => toggle() : undefined}
                  >
                    <span className="underline-on-hover">Videos</span>
                  </Link>
                </li>
                <li
                  className={cx(styles.menuItem, open ? styles.reveal : "")}
                  key="about"
                >
                  <Link
                    activeClassName="active"
                    className={cx("label", styles.menuLink)}
                    to="/about"
                    onClick={open ? () => toggle() : undefined}
                  >
                    <span className="underline-on-hover">About</span>
                  </Link>
                </li>
                <li
                  className={cx(styles.menuItem, open ? styles.reveal : "")}
                  key="bandcamp"
                >
                  <a
                    className={cx("label", styles.menuLink)}
                    href="https://homevideo.bandcamp.com"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <SiBandcamp />{" "}
                    <span className="underline-on-hover">Shop</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
